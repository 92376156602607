import { Col, Row } from "react-bootstrap";
import BOK from "./BOK.js";

function BOKEggGroupComponent() {
    return (
        <Col className="col-12">
            {BOK.EggGroup.getAll().map((group) => (
                <Row>
                    <Col className="col-12 text-dark bg-light"><img src={group.Image} /><span className="fs-3 align-middle">{group.id}</span></Col>
                    <Col className="pb-3 bg-info">
                        {BOK.EggGroup.getMonstersByEggGroup(group.guid).map((mon) => (
                            <>
                                {BOK.Mutation.getMutationsFromByGuid(mon.guid).length < 1 ? (
                                    <img
                                        className="pixelated"
                                        src={mon.Images.find(image => image.id === "Portrait").File}
                                        alt={mon.id}
                                        width="60"
                                        height="60"
                                    />
                                ) : (<></>)}
                            </>
                        ))}
                    </Col>
                </Row>
            ))}

        </Col>
    );
}
export default BOKEggGroupComponent;