import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import BOK from "./BOK.js";
import RadarChart from "./RadarChart.js";

function BOKMonsterCompareComponent(props) {
  const params = useParams();
  const navigate = useNavigate();

  // Destructure MON1 and MON2 from props or params
  const MON1 = props.MON1 || params.MON1;
  const MON2 = props.MON2 || params.MON2;

  // Get all monsters for select options
  const allMons = BOK.Monster.getAll();
  const selectOptions = allMons.map(mon => (
    <option key={mon.INDEX} value={mon.INDEX}>
      {mon.id} #{mon.INDEX}
    </option>
  ));

  const handleSelectChange = (e, type) => {
    const newIndex = e.target.value;

    // Determine new navigation path
    let path = '';

    if (type === 'mon1') {
      // Update MON1 and navigate
      if (MON2) {
        path = `/dex/compare/${newIndex}/${MON2}`;
      } else {
        path = `/dex/compare/${newIndex}`;
      }
    } else if (type === 'mon2') {
      // Update MON2 and navigate
      if (MON1) {
        path = `/dex/compare/${MON1}/${newIndex}`;
      } else {
        path = `/dex/compare/${newIndex}`;
      }
    }

    // Debugging output to ensure correct path
    console.log(`Navigating to: ${path}`);

    // Navigate to the determined path
    navigate(path);
  };


  const mon1 = MON1 ? BOK.Monster.getByIndex(MON1) : null;
  const mon2 = MON2 ? BOK.Monster.getByIndex(MON2) : null;

  return (
    <>
      <Col className="col-12 flex-grow-1">
        <Row className="h-100">
          <Col className="border-end border-primary border-1 col-6">
            {MON1 ? (
              <Row>
                <div className="col-12 p-0">
                  <Form.Select
                    aria-label="Select Monster"
                    value={mon1.INDEX}
                    onChange={(e) => handleSelectChange(e, 'mon1')}
                    className="text-white py-0 mb-0 border-0"
                  >
                    {selectOptions}
                  </Form.Select>
                  <div className="text-center bg-white">
                    <img className="w-75 pixelated" src={mon1.Images.find(image => image.id === "Base").File} alt={mon1.id} />
                  </div>
                  <div className="text-center bg-info">
                    <h5 className="text-black lh-1 mb-0">{mon1.id}</h5>
                    <p className="mb-0">
                      <span className="fs-8 text-black align-middle d-inline">
                        <img className="pixelated" alt={BOK.Element.getByGuid(mon1.Type1).id} title={BOK.Element.getByGuid(mon1.Type1).id} src={BOK.Element.getByGuid(mon1.Type1).Image} />
                        <span className="d-none d-sm-inline">{BOK.Element.getByGuid(mon1.Type1).id}</span>
                      </span>
                      <span className="fs-8 text-black align-middle d-inline">
                        <img className="pixelated" alt={BOK.Element.getByGuid(mon1.Type2).id} title={BOK.Element.getByGuid(mon1.Type2).id} src={BOK.Element.getByGuid(mon1.Type2).Image} />
                        <span className="d-none d-sm-inline">{BOK.Element.getByGuid(mon1.Type2).id}</span>
                      </span>
                      <span className="fs-8 text-black align-middle d-inline">
                        <img className="pixelated" alt={BOK.EggGroup.getByGuid(mon1.EggGroup).id} title={BOK.EggGroup.getByGuid(mon1.EggGroup).id} src={BOK.EggGroup.getByGuid(mon1.EggGroup).Image} />
                        <span className="d-none d-sm-inline">{BOK.EggGroup.getByGuid(BOK.EggGroup.getMonsterEggGroupByGuid(mon1.guid)).id}</span>
                      </span>
                    </p>
                  </div>
                </div>
                <Col className="col-12">
                  <RadarChart mon={mon1} />
                </Col>
                {BOK.Move.getMonsterMovesByGuid(mon1.guid).length > 0 && (
                  <Col className="col-12">
                    <Row className="fs-8 border-bottom text-primary justify-content-center">
                      <Col className="col-2 px-1 text-center">Level</Col>
                      <Col className="col-7 px-1">Name</Col>
                      <Col className="col-3 px-1">Element</Col>
                    </Row>
                    {BOK.Move.getMonsterMovesByGuid(mon1.guid).map((move) => (
                      <Row className="fs-7 border-bottom justify-content-center" key={move.Move}>
                        <Col className="col-2 px-1 text-center">{move.Level}</Col>
                        <Col className="col-7 px-1">
                          <span className="d-sm-none fs-8">{BOK.Move.getByGuid(move.Move).id}</span>
                          <span className="d-none d-sm-inline fs-7">{BOK.Move.getByGuid(move.Move).id}</span>
                        </Col>
                        <Col className="col-3 px-1" style={{ whiteSpace: "nowrap" }}>
                          <img className="d-none d-sm-inline pixelated" alt={BOK.Element.getByGuid(BOK.Move.getByGuid(move.Move).Element).id} src={BOK.Element.getByGuid(BOK.Move.getByGuid(move.Move).Element).Image} style={{ width: "16px" }} />
                          <img className="d-sm-none pixelated" alt={BOK.Element.getByGuid(BOK.Move.getByGuid(move.Move).Element).id} src={BOK.Element.getByGuid(BOK.Move.getByGuid(move.Move).Element).Image} style={{ width: "8px" }} />
                          <span className="d-none d-sm-inline fs-8">{BOK.MoveType.getByGuid(BOK.Move.getByGuid(move.Move).MoveType).id}</span>
                          <span className="d-sm-none fs-8">{BOK.MoveType.getByGuid(BOK.Move.getByGuid(move.Move).MoveType).id.substring(0, 4)}</span>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                )}
              </Row>
            ) : (
              <Row>
                <div className="col-12 p-0">
                  <Form.Select
                    aria-label="Select Monster"
                    onChange={(e) => handleSelectChange(e, 'mon1')}
                    className="text-white py-0 mb-0 border-0"

                  >
                    <option value="">Select Monster</option>
                    {selectOptions}
                  </Form.Select>
                </div>
              </Row>
            )}
          </Col>
          <Col className="border-end border-primary border-1 col-6">
            {MON2 ? (
              <Row>
                <div className="col-12 p-0">
                  <Form.Select
                    aria-label="Select Monster"
                    value={mon2.INDEX}
                    onChange={(e) => handleSelectChange(e, 'mon2')}
                    className="text-white py-0 mb-0 border-0"
                  >
                    {selectOptions}
                  </Form.Select>
                  <div className="text-center bg-white">
                    <img className="w-75 pixelated" src={mon2.Images.find(image => image.id === "Base").File} alt={mon2.id} />
                  </div>
                  <div className="text-center bg-info">
                    <h5 className="text-black lh-1 mb-0">{mon2.id}</h5>
                    <p className="mb-0">
                      <span className="fs-8 text-black align-middle d-inline">
                        <img className="pixelated" alt={BOK.Element.getByGuid(mon2.Type1).id} title={BOK.Element.getByGuid(mon2.Type1).id} src={BOK.Element.getByGuid(mon2.Type1).Image} />
                        <span className="d-none d-sm-inline">{BOK.Element.getByGuid(mon2.Type1).id}</span>
                      </span>
                      <span className="fs-8 text-black align-middle d-inline">
                        <img className="pixelated" alt={BOK.Element.getByGuid(mon2.Type2).id} title={BOK.Element.getByGuid(mon2.Type2).id} src={BOK.Element.getByGuid(mon2.Type2).Image} />
                        <span className="d-none d-sm-inline">{BOK.Element.getByGuid(mon2.Type2).id}</span>
                      </span>
                      <span className="fs-8 text-black align-middle d-inline">
                        <img className="pixelated" alt={BOK.EggGroup.getByGuid(mon2.EggGroup).id} title={BOK.EggGroup.getByGuid(mon2.EggGroup).id} src={BOK.EggGroup.getByGuid(mon2.EggGroup).Image} />
                        <span className="d-none d-sm-inline">{BOK.EggGroup.getByGuid(BOK.EggGroup.getMonsterEggGroupByGuid(mon2.guid)).id}</span>
                      </span>
                    </p>
                  </div>
                </div>
                <Col className="col-12">
                  <RadarChart mon={mon2} />
                </Col>
                {BOK.Move.getMonsterMovesByGuid(mon2.guid).length > 0 && (
                  <Col className="col-12">
                    <Row className="fs-8 border-bottom text-primary justify-content-center">
                      <Col className="col-2 px-1 text-center">Level</Col>
                      <Col className="col-7 px-1">Name</Col>
                      <Col className="col-3 px-1">Element</Col>
                    </Row>
                    {BOK.Move.getMonsterMovesByGuid(mon2.guid).map((move) => (
                      <Row className="fs-7 border-bottom justify-content-center" key={move.Move}>
                        <Col className="col-2 px-1 text-center">{move.Level}</Col>
                        <Col className="col-7 px-1">
                          <span className="d-sm-none fs-8">{BOK.Move.getByGuid(move.Move).id}</span>
                          <span className="d-none d-sm-inline fs-7">{BOK.Move.getByGuid(move.Move).id}</span>
                        </Col>
                        <Col className="col-3 px-1" style={{ whiteSpace: "nowrap" }}>
                          <img className="d-none d-sm-inline pixelated" alt={BOK.Element.getByGuid(BOK.Move.getByGuid(move.Move).Element).id} src={BOK.Element.getByGuid(BOK.Move.getByGuid(move.Move).Element).Image} style={{ width: "16px" }} />
                          <img className="d-sm-none pixelated" alt={BOK.Element.getByGuid(BOK.Move.getByGuid(move.Move).Element).id} src={BOK.Element.getByGuid(BOK.Move.getByGuid(move.Move).Element).Image} style={{ width: "8px" }} />
                          <span className="d-none d-sm-inline fs-8">{BOK.MoveType.getByGuid(BOK.Move.getByGuid(move.Move).MoveType).id}</span>
                          <span className="d-sm-none fs-8">{BOK.MoveType.getByGuid(BOK.Move.getByGuid(move.Move).MoveType).id.substring(0, 4)}</span>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                )}
              </Row>
            ) : (
              <Row>
                <div className="col-12 p-0">
                  <Form.Select
                    aria-label="Select Monster"
                    onChange={(e) => handleSelectChange(e, 'mon2')}
                    className="text-white py-0 mb-0 border-0"
                    disabled={!MON1}
                  >
                    <option value="">Select Monster</option>
                    {selectOptions}
                  </Form.Select>
                </div>
              </Row>
            )}

          </Col>
        </Row>
      </Col>
    </>
  );
}

export default BOKMonsterCompareComponent;
