import { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import BOK from "./BOK.js";

function BOKListComponent() {
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedEggGroups, setSelectedEggGroups] = useState([]);

  const monsters = BOK.Monster.getAll();
  const elements = BOK.Element.getAll();
  const eggGroups = BOK.EggGroup.getAll();

  const handleTypeFilter = (typeGuid) => {
    setSelectedTypes((prev) =>
      prev.includes(typeGuid)
        ? prev.filter((guid) => guid !== typeGuid)
        : [...prev, typeGuid]
    );
  };

  const handleEggGroupFilter = (eggGroupGuid) => {
    setSelectedEggGroups((prev) =>
      prev.includes(eggGroupGuid)
        ? prev.filter((guid) => guid !== eggGroupGuid)
        : [...prev, eggGroupGuid]
    );
  };

  const filteredMonsters = monsters.filter((mon) => {
    const matchesType =
      selectedTypes.length === 0 ||
      selectedTypes.includes(mon.Type1) ||
      selectedTypes.includes(mon.Type2);
    const matchesEggGroup =
      selectedEggGroups.length === 0 ||
      selectedEggGroups.includes(mon.EggGroup);

    return matchesType && matchesEggGroup;
  });

  const scrollToTop = () => {
    const container = document.querySelector("#container");
    if (container) {
      container.scrollTop = 0;
    }
  };

  const applyOpacity = (guid, selectedList) => {
    if (selectedTypes.length === 0 && selectedEggGroups.length === 0) {
      return 1; // No filters selected, full opacity
    }
    return selectedList.includes(guid) ? 1 : 0.25; // Selected: full opacity; Unselected: 50% opacity
  };

  return (
    <>
      <Col className="col-12">
        {/* Filter Section */}
        <Row className="">
          <Col className="col-12 bg-success-subtle py-1">
            <div className="d-flex flex-wrap justify-content-center">
              {/* Element Filters */}
              {elements.map((element) => (
                <img
                  key={element.guid}
                  className={`pixelated mx-1`}
                  src={element.Image}
                  alt={element.id}
                  title={element.id}
                  onClick={() => handleTypeFilter(element.guid)}
                  style={{
                    cursor: "pointer",
                    width: "32px",
                    height: "32px",
                    opacity: applyOpacity(element.guid, selectedTypes),
                  }}
                />
              ))}

              {/* Egg Group Filters */}
              {eggGroups.map((eggGroup) => (
                <img
                  key={eggGroup.guid}
                  className={`pixelated mx-1`}
                  src={eggGroup.Image}
                  alt={eggGroup.id}
                  title={eggGroup.id}
                  onClick={() => handleEggGroupFilter(eggGroup.guid)}
                  style={{
                    cursor: "pointer",
                    width: "32px",
                    height: "32px",
                    opacity: applyOpacity(eggGroup.guid, selectedEggGroups),
                  }}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Col>

      <Col className="col-12">
        <Row>
          {filteredMonsters
            .sort((a, b) => a.INDEX.localeCompare(b.INDEX))
            .map((mon) => (
              <Col key={mon.INDEX} className="col-sm-4 col-6 text-center g-2">
                <a
                  href={`/#/dex/mon/${mon.INDEX}`}
                  className="text-decoration-none"
                  onClick={scrollToTop}
                >
                  <Card className="bg-white overflow-hidden lh-1">
                    <Card.Img
                      className="pixelated h-100"
                      variant="top"
                      src={mon.Images.find(image => image.id === "Base").File}
                      width="128"
                      height="128"
                    />
                    <Card.Body className="p-1 bg-info">
                      <Card.Title className="mb-0 fs-6">
                        <small className="badge rounded-pill text-black bg-light">
                          #{mon.INDEX}
                        </small>
                      </Card.Title>
                      <Card.Text className="fs-6">
                        {mon.id}
                        <br />
                        <img
                          className="pixelated"
                          alt={BOK.Element.getByGuid(mon.Type1).id}
                          title={BOK.Element.getByGuid(mon.Type1).id}
                          src={BOK.Element.getByGuid(mon.Type1).Image}
                        />
                        {mon.Type2 && (
                          <img
                            className="pixelated"
                            alt={BOK.Element.getByGuid(mon.Type2).id}
                            title={BOK.Element.getByGuid(mon.Type2).id}
                            src={BOK.Element.getByGuid(mon.Type2).Image}
                          />
                        )}
                        <img
                          className="pixelated"
                          alt={BOK.EggGroup.getByGuid(mon.EggGroup).id}
                          title={BOK.EggGroup.getByGuid(mon.EggGroup).id}
                          src={BOK.EggGroup.getByGuid(mon.EggGroup).Image}
                        />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            ))}
        </Row>
      </Col>
    </>
  );
}

export default BOKListComponent;
